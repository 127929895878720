<template>
  <side-content>
    <template v-slot:header>
      <v-btn
        class="mr-3"
        color="#E5E5EA"
        x-small
        depressed
        fab
        @click="goToTicketsList">
        <v-icon color="primary">
          {{ $root.icons.action.back }}
        </v-icon>
      </v-btn>
      Criar Título
    </template>

    <v-form
      ref="form"
      v-model="valid"
      class="mt-4"
      lazy-validation>
      <v-layout
        v-for="(ticket, index) in newTickets"
        :key="index"
        row
        wrap>
        <v-flex
          sm12>
          <date-picker
            label="Vencimento"
            :date="ticket.date"
            :index="index"
            @change="saveTicketsDate" />
        </v-flex>
        <v-flex
          sm12>
          <v-text-field
            v-model="ticket.originalValue"
            v-money="money"
            label="Valor"
            prefix="R$"
            filled
            :rules="currencyRules"
            validate-on-blur />
        </v-flex>
        <v-flex
          sm12>
          <v-text-field
            v-model="ticket.description"
            filled
            label="Descrição" />
        </v-flex>
        <v-flex
          sm10>
          <v-text-field
            v-model="ticket.id"
            filled
            label="Identificação" />
        </v-flex>
        <v-flex
          sm2>
          <v-btn
            v-if="index > 0"
            fab
            x-small
            depressed
            class="mini mr-2 mt-2"
            @click="removeTicket(ticket)">
            <v-icon
              size="14px"
              dark
              color="error">
              {{ $root.icons.action.remove }}
            </v-icon>
          </v-btn>
          <v-btn
            v-if="showAddTicket(index)"
            fab
            x-small
            depressed
            class="mini mt-2"
            @click="addTicket">
            <v-icon
              size="14px"
              dark
              color="success">
              {{ $root.icons.action.add }}
            </v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>

    <template v-slot:footer>
      <v-btn
        block
        depressed
        :disabled="!valid"
        color="primary"
        :loading="isLoading"
        title="Adicionar novos títulos"
        @click="submitTickets">
        Adicionar
      </v-btn>
    </template>
  </side-content>
</template>

<script>
  import DatePicker from '@/components/ui/DatePicker'
  import validate from '@/mixins/validate'
  import masks from '@/mixins/masks'
  import casesMixin from '@/mixins/cases'
  import { mapGetters } from 'vuex'

  export default {
    components: { DatePicker },
    mixins: [ casesMixin, validate, masks ],
    data () {
      return {
        valid: true,
        caseId: this.$route.params.caseId,
        newTickets: [
          {dueAt: '', originalValue: '', description: '', id: ''}
        ]
      }
    },
    computed: {
      ...mapGetters({
        isLoading: 'app/getLoading'
      })
    },
    methods: {
      goToTicketsList () {
        this.$router.push({ name: 'CaseTicketsList' })
      },
      removeTicket (ticket) {
        this.newTickets = this.newTickets.filter(record => record !== ticket)
      },
      addTicket () {
        let date = new Date()
        this.newTickets.push({dueAt: '', originalValue: '', description: '', id: '', timestamp: date.getTime()})
      },
      showAddTicket (index) {
        return this.newTickets.length === index + 1
      },
      saveTicketsDate (date, index) {
        this.newTickets[index].dueAt = date
      },
      submitTickets () {
        if (this.$refs.form.validate()) {
          const tickets = JSON.parse(JSON.stringify(this.newTickets)).map(ticket => {
            ticket.dueAt = ticket.dueAt.split('/').reverse().join('-')
            ticket.originalValue = ticket.originalValue = typeof ticket.originalValue === 'number' ? ticket.originalValue : parseFloat(ticket.originalValue.replace('.', '').replace(',', '.'))
            return ticket
          })
          let data = Object.assign({caseId: this.caseId, tickets })
          this
            .$store
            .dispatch('cases/addTickets', data)
            .then(this._success)
            .catch(this._failure)
        }
      },
      _success () {
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Títulos adicionados com sucesso'})
        this.goToTicketsList()
      },
      _failure (error) {
        console.log(error)
        this.$root.$emit('snackbar', {show: true, color: 'error', text: 'Houve um problema no envio, tente novamente'})
      }
    }
  }
</script>

<style lang="sass">
  .btn.mini
    width: 35px
    height: 35px
</style>
