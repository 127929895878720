<template>
  <side-content>
    <template v-slot:header>
      Títulos
      <v-spacer />
      <v-btn
        fab
        depressed
        x-small
        color="#E5E5EA"
        :to="{ name: 'CaseTicketsNew' }">
        <v-icon color="primary">
          add
        </v-icon>
      </v-btn>
      <v-btn
        class="ml-2"
        color="#E5E5EA"
        fab
        depressed
        x-small
        :to="{ name: 'CaseShow' }">
        <v-icon color="primary">
          close
        </v-icon>
      </v-btn>
    </template>

    <loading v-if="loading > 0" class="absolute-center" />

    <v-layout
      v-else
      row
      wrap>
      <v-flex
        xs12
        sm12
        class="py-0">
        <v-data-table
          class="elevation-0"
          item-key="currentValue"
          :headers="headers"
          :items="caseRecord.tickets"
          :single-expand="true"
          :expanded.sync="expanded"
          hide-default-footer>
          <template v-slot:item="{ item, isExpanded }">
            <tr :class="{'table-list-opened': isExpanded }">
              <td :class="{'text-line-throug': item.ignoreOnInitialProposal }" class="px-2 caption" @click="toggleExpand(item)">
                {{ new Date(item.dueAt.replace(/-/g, '/')).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: '2-digit'}) }}
              </td>
              <td :class="{'text-line-throug': item.ignoreOnInitialProposal }" class="px-2 caption" @click="toggleExpand(item)">
                {{ $n(item.originalValue, 'currency') }}
              </td>
              <td :class="{'text-line-throug': item.ignoreOnInitialProposal }" class="px-2 caption" @click="toggleExpand(item)">
                {{ $n(item.currentValue, 'currency') }}
              </td>
              <td class="px-0">
                <v-menu offset-y left>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      small
                      class="mx-0"
                      v-on="on">
                      <v-icon
                        size="26px"
                        color="primary">
                        {{ $root.icons.layout.more_y }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item :disabled="ticketsSize === 1" @click="remove(item)">
                      <v-list-item-title>Remover Título</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="canIgnore" :disabled="ticketsSize === 1" @click="change(item, !item.ignoreOnInitialProposal)">
                      <v-list-item-title v-if="!item.ignoreOnInitialProposal">
                        Ignorar Título
                      </v-list-item-title>
                      <v-list-item-title v-else>
                        Aceitar Título
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <tr>
              <td class="pa-0" :colspan="headers.length">
                <v-list color="grey lighten-4" dense>
                  <list-item label="Identificação" :value="item.id === '' ? '---' : item.id" divider />
                  <list-item label="Descricao" :value="item.description === '' ? '---' : item.description" divider />
                  <list-item
                    v-for="(detail, index) in item.details"
                    :key="index"
                    :label="$t('calculateParams.' + detail.type)"
                    :value="$n(detail.value, 'currency')"
                    :info="detail.percent.toFixed(2) + '%'"
                    :divider="index + 1 < item.details.length" />
                </v-list>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <modal
      btn-primary-label="Remover"
      max-width="480"
      :show="showModal"
      @close="closeModal"
      @submit="saveModal">
      <span slot="title">Remover Título do Caso</span>
      <div slot="content">
        <p>Tem certeza que deseja remover o título do caso, esta ação não poderá ser desfeita</p>
      </div>
    </modal>
  </side-content>
</template>

<script>
  import Modal from '@/components/ui/Modal'
  import validate from '@/mixins/validate'
  import casesMixin from '@/mixins/cases'
  import { mapGetters } from 'vuex'

  export default {
    components: { Modal },
    mixins: [ casesMixin, validate ],
    data () {
      return {
        expanded: [],
        options: { suppressScrollX: true, wheelPropagation: false },
        caseId: this.$route.params.caseId,
        valid: true,
        showModal: false,
        ticketToRemove: null,
        headers: [
          { text: 'Data', value: 'dueAt', sortable: false, align: 'center' },
          { text: 'Original', value: 'originalValue', sortable: false, align: 'left' },
          { text: 'Atualizado', value: 'currentValue', sortable: false, align: 'left' },
          { text: '', value: 'actions', sortable: false, align: 'center' }
        ]
      }
    },
    computed: {
      ticketsSize () {
        return this.caseRecord.tickets.length
      },
      ...mapGetters({
        companyId: 'currentCompany/id',
        event: 'events/record'
      }),
      canIgnore () {
        return this.event && this.event.calculateParams.enableIgnoreTicketsOnInitialProposal
      }
    },
    beforeMount () {
      if (this.caseRecord && this.caseRecord.eventId) {
        this.$store.dispatch('events/get', this.caseRecord.eventId)
      }
    },
    methods: {
      parseValue (detail) {
        return `(${detail.percent.toFixed(2)})% ${this.$n(detail.value, 'currency')}`
      },
      remove (item) {
        const index = this.caseRecord.tickets.indexOf(item)
        this.$root.$emit('snackbar', {show: false})
        this.showModal = true
        this.ticketToRemove = item
      },
      change (ticket, ignoreOnInitialProposal) {
        const data = Object.assign({caseId: this.caseId, ticket, ignoreOnInitialProposal })
        this
          .$store
          .dispatch('cases/updateTicket', data)
          .then(this._success)
          .catch(this._failure)
      },
      closeModal () {
        this.showModal = false
        this.ticketToRemove = null
      },
      saveModal () {
        let data = Object.assign({caseId: this.caseId, ticket: this.ticketToRemove })
        this
          .$store
          .dispatch('cases/removeTicket', data)
          .then(this._success)
          .catch(this._failure)
      },
      createNewTicket () {
        this.$router.push({ name: 'CaseTicketsNew' })
      },
      _success () {
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Títulos alterados com sucesso'})
        this.closeModal()
      },
      _failure () {
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'houve um problema, tente novamente'})
      },
      toggleExpand(item) {
        this.expanded = this.expanded.includes(item) ? [] : [item]
      }
    }
  }
</script>

<style lang="sass">
  .btn-fixed-bottom
    position: fixed
    bottom: 0
    right: 10px

    &:hover
      position: fixed

  .case-tickets
    height: 100%
    transform: translateX(0)
    overflow-y: hidden

    .btn-tooltip
      z-index: 1

  .ps
    height: calc(100vh - 100px)
</style>
