var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('side-content',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Títulos "),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","depressed":"","x-small":"","color":"#E5E5EA","to":{ name: 'CaseTicketsNew' }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" add ")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"#E5E5EA","fab":"","depressed":"","x-small":"","to":{ name: 'CaseShow' }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" close ")])],1)]},proxy:true}])},[(_vm.loading > 0)?_c('loading',{staticClass:"absolute-center"}):_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"py-0",attrs:{"xs12":"","sm12":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"item-key":"currentValue","headers":_vm.headers,"items":_vm.caseRecord.tickets,"single-expand":true,"expanded":_vm.expanded,"hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [_c('tr',{class:{'table-list-opened': isExpanded }},[_c('td',{staticClass:"px-2 caption",class:{'text-line-throug': item.ignoreOnInitialProposal },on:{"click":function($event){return _vm.toggleExpand(item)}}},[_vm._v(" "+_vm._s(new Date(item.dueAt.replace(/-/g, '/')).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: '2-digit'}))+" ")]),_c('td',{staticClass:"px-2 caption",class:{'text-line-throug': item.ignoreOnInitialProposal },on:{"click":function($event){return _vm.toggleExpand(item)}}},[_vm._v(" "+_vm._s(_vm.$n(item.originalValue, 'currency'))+" ")]),_c('td',{staticClass:"px-2 caption",class:{'text-line-throug': item.ignoreOnInitialProposal },on:{"click":function($event){return _vm.toggleExpand(item)}}},[_vm._v(" "+_vm._s(_vm.$n(item.currentValue, 'currency'))+" ")]),_c('td',{staticClass:"px-0"},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-0",attrs:{"icon":"","small":""}},on),[_c('v-icon',{attrs:{"size":"26px","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$root.icons.layout.more_y)+" ")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"disabled":_vm.ticketsSize === 1},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-title',[_vm._v("Remover Título")])],1),(_vm.canIgnore)?_c('v-list-item',{attrs:{"disabled":_vm.ticketsSize === 1},on:{"click":function($event){return _vm.change(item, !item.ignoreOnInitialProposal)}}},[(!item.ignoreOnInitialProposal)?_c('v-list-item-title',[_vm._v(" Ignorar Título ")]):_c('v-list-item-title',[_vm._v(" Aceitar Título ")])],1):_vm._e()],1)],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
var headers = ref.headers;
return [_c('tr',[_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-list',{attrs:{"color":"grey lighten-4","dense":""}},[_c('list-item',{attrs:{"label":"Identificação","value":item.id === '' ? '---' : item.id,"divider":""}}),_c('list-item',{attrs:{"label":"Descricao","value":item.description === '' ? '---' : item.description,"divider":""}}),_vm._l((item.details),function(detail,index){return _c('list-item',{key:index,attrs:{"label":_vm.$t('calculateParams.' + detail.type),"value":_vm.$n(detail.value, 'currency'),"info":detail.percent.toFixed(2) + '%',"divider":index + 1 < item.details.length}})})],2)],1)])]}}])})],1)],1),_c('modal',{attrs:{"btn-primary-label":"Remover","max-width":"480","show":_vm.showModal},on:{"close":_vm.closeModal,"submit":_vm.saveModal}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Remover Título do Caso")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('p',[_vm._v("Tem certeza que deseja remover o título do caso, esta ação não poderá ser desfeita")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }