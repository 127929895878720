<template>
  <section class="date-picker">
    <v-menu
      v-model="menuDate"
      content-class="date-picker-menu"
      transition="scale-transition"
      max-width="290px"
      min-width="290px"
      :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="dateFormatted"
          v-mask="mask.date"
          append-icon="event"
          :disabled="isDisabled"
          :label="label"
          :rules="dateRules"
          required
          v-on="on"
          @blur="blurDate" />
      </template>

      <v-date-picker
        v-model="setDate"
        locale="pt-br"
        :max="setMax"
        no-title
        @input="changeDate" />
    </v-menu>
  </section>
</template>

<script>
  import validate from '@/mixins/validate'
  import masks from '@/mixins/masks'

  export default {
    mixins: [validate, masks],
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      date: {
        type: String,
        required: true,
        default: ''
      },
      label: {
        type: String,
        default: 'Data de nascimento'
      },
      index: {
        type: Number
      },
      max: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      setDate: null,
      dateFormatted: null,
      menu1: false,
      menuDate: false,
      isDisabled: null,
      setMax: null
    }),
    mounted () {
      // this.setDate = this.parseDate(this.date)
      this.isDisabled = this.disabled
      this.max.length > 0 && this.setMaxDate()
      // this.setMaxDate()

      this.dateFormatted = this.date
    },
    methods: {
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      changeDate (date) {
        this.dateFormatted = this.formatDate(date)
        this.menuDate = false
        this.$emit('change', this.dateFormatted, this.index)
      },
      setMaxDate () {
        let date = this.max
        let [month, day, year] = date.split('/')
        this.setMax = `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}`
      },
      blurDate (event) {
        this.$emit('change', event.target.value, this.index)
      }
      // parseDate (date) {
      //   if (!date) return null

      //   const [month, day, year] = date.split('/')
      //   return `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}`
      // }
    }
  }
</script>

<style lang="sass">
  .date-picker-menu
    margin-top: 70px
</style>
